body {
  margin: 0;
  font-family: "Noto Sans Bengali", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;200;300;400;500;600;700&display=swap");

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: rgba(36, 37, 38, 1);
}

h1 {
  font-size: 32px;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}

p {
  font-size: 17px;
  margin-top: 20px;
  line-height: 1.5;
}

span {
  font-weight: 600;
}
